<template>
    <div>
      <div class="terms-conditions mt-5 pt-5">
        <div class="container">
          <div class="row">
            <div class="col-md-10 main-heading mb-5">
              <h3 class="mb-1">Terms and Conditions</h3>
              <div class="divider"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <!-- Introduction -->
              <div class="mb-4">
                <h5>Last Updated: April 27, 2024</h5>
                <p><strong>BRIKS Technology</strong> ("we," "our," or "us") provides the <strong>BRIKS.AI</strong> app ("the App") to you subject to the following Terms and Conditions ("Terms"). By accessing or using the App, you agree to comply with and be bound by these Terms. Please read them carefully.</p>
              </div>
  
              <!-- 1. Acceptance of Terms -->
              <div class="mb-4">
                <h5>1. Acceptance of Terms</h5>
                <p>By creating an account and using the App, you agree to these Terms and our <a href="/privacy-policy">Privacy Policy</a>. If you do not agree to these Terms, please do not use the App.</p>
              </div>
  
              <!-- 2. Eligibility -->
              <div class="mb-4">
                <h5>2. Eligibility</h5>
                <p>You must be at least 13 years old to use the App. By using the App, you represent and warrant that you meet the eligibility requirements.</p>
              </div>
  
              <!-- 3. Account Registration -->
              <div class="mb-4">
                <h5>3. Account Registration</h5>
                <ul>
                  <li><strong>Accurate Information:</strong> You agree to provide accurate, current, and complete information during registration and to update such information as necessary.</li>
                  <li><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your password and for all activities that occur under your account.</li>
                  <li><strong>Account Termination:</strong> We reserve the right to terminate your account if any information provided is found to be inaccurate or if you violate these Terms.</li>
                </ul>
              </div>
  
              <!-- 4. Use of the App -->
              <div class="mb-4">
                <h5>4. Use of the App</h5>
                <ul>
                  <li><strong>Permitted Use:</strong> You may use the App to create memes and images using our AI tools for personal and non-commercial purposes.</li>
                  <li><strong>Prohibited Use:</strong>
                    <ul>
                      <li>Use the App for any unlawful purpose.</li>
                      <li>Upload, post, or distribute content that is defamatory, obscene, infringing, or otherwise objectionable.</li>
                      <li>Attempt to gain unauthorized access to the App or its systems.</li>
                      <li>Use automated means to access the App without our explicit permission.</li>
                      <li>Create, distribute, or promote content that violates any applicable laws or regulations.</li>
                      <li>Generate content that is harmful, harassing, or discriminatory towards any individual or group.</li>
                    </ul>
                  </li>
                </ul>
              </div>
  
              <!-- 5. User-Generated Content -->
              <div class="mb-4">
                <h5>5. User-Generated Content</h5>
                <ul>
                  <li><strong>Ownership:</strong> You retain ownership of the content you create using the App. However, by creating content, you grant BRIKS Technology a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute your content in connection with the App.</li>
                  <li><strong>Responsibility:</strong> You are solely responsible for the content you create and share. We do not endorse or guarantee the accuracy, integrity, or quality of AI-generated content.</li>
                  <li><strong>Content Moderation:</strong> We reserve the right to monitor, review, and remove any content that violates these Terms or is deemed inappropriate, at our sole discretion.</li>
                  <li><strong>Reporting Inappropriate Content:</strong> Users can report content they believe violates these Terms. We will investigate and take appropriate action as necessary.</li>
                </ul>
              </div>
  
              <!-- 6. Intellectual Property -->
              <div class="mb-4">
                <h5>6. Intellectual Property</h5>
                <ul>
                  <li><strong>App Content:</strong> All content, features, and functionality of the App, including but not limited to text, graphics, logos, and software, are the exclusive property of BRIKS Technology and are protected by intellectual property laws.</li>
                  <li><strong>Trademarks:</strong> The trademarks, logos, and service marks displayed on the App are the property of BRIKS Technology or other third parties.</li>
                </ul>
              </div>
  
              <!-- 7. Payment and Refunds -->
              <div class="mb-4">
                <h5>7. Payment and Refunds</h5>
                <ul>
                  <li><strong>Pricing:</strong> [Include details about any paid features, subscription plans, pricing, and billing cycles.]</li>
                  <li><strong>Refunds:</strong> [Specify your refund policy, if applicable.]</li>
                </ul>
              </div>
  
              <!-- 8. Termination -->
              <div class="mb-4">
                <h5>8. Termination</h5>
                <p>We may terminate or suspend your access to the App immediately, without prior notice or liability, for any reason, including if you breach the Terms.</p>
              </div>
  
              <!-- 9. Disclaimer of Warranties -->
              <div class="mb-4">
                <h5>9. Disclaimer of Warranties</h5>
                <p>The App is provided "as is" and "as available" without any warranties of any kind, either express or implied. We do not warrant that the App will be uninterrupted, error-free, or secure.</p>
              </div>
  
              <!-- 10. Limitation of Liability -->
              <div class="mb-4">
                <h5>10. Limitation of Liability</h5>
                <p>To the fullest extent permitted by law, BRIKS Technology shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use of the App.</p>
              </div>
  
              <!-- 11. Indemnification -->
              <div class="mb-4">
                <h5>11. Indemnification</h5>
                <p>You agree to indemnify, defend, and hold harmless BRIKS Technology and its affiliates, officers, agents, and employees from any claims, liabilities, damages, losses, and expenses arising out of your use of the App or violation of these Terms.</p>
              </div>
  
              <!-- 12. Governing Law -->
              <div class="mb-4">
                <h5>12. Governing Law</h5>
                <p>These Terms shall be governed by and construed in accordance with the laws of United States, without regard to its conflict of law principles.</p>
              </div>
  
              <!-- 13. Dispute Resolution -->
              <div class="mb-4">
                <h5>13. Dispute Resolution</h5>
                <ul>
                  <li><strong>Arbitration:</strong> Any disputes arising out of or related to these Terms or the App shall be resolved through binding arbitration in United States.</li>
                  <li><strong>Class Action Waiver:</strong> You agree not to participate in a class action lawsuit against BRIKS Technology.</li>
                </ul>
              </div>
  
              <!-- 14. Changes to Terms -->
              <div class="mb-4">
                <h5>14. Changes to Terms</h5>
                <p>We may modify these Terms at any time. Changes will be effective immediately upon posting on the App. Your continued use of the App constitutes acceptance of the revised Terms.</p>
              </div>
  
              <!-- 15. Contact Us -->
              <div class="mb-4">
                <h5>15. Contact Us</h5>
                <p>If you have any questions about these Terms, please contact us at:</p>
                <p><strong>BRIKS Technology</strong><br>
                  Email: <a href="mailto:hello@briks.ai">hello@briks.ai</a><br>
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TermsAndConditions'
  }
  </script>
  
  <style scoped>
  .terms-conditions {
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .main-heading h3 {
    font-size: 2.5rem;
    text-align: center;
  }
  
  .divider {
    width: 100px;
    height: 4px;
    background-color: #007BFF;
    margin: 10px auto;
  }
  
  h5 {
    color: #007BFF;
  }
  
  h4 {
    color: #0056b3;
    margin-top: 20px;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  ul ul {
    list-style-type: circle;
    padding-left: 20px;
  }
  
  a {
    color: #007BFF;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .main-heading h3 {
      font-size: 2rem;
    }
  }
  </style>
  